.header {
    height: 120px;
    background-color: transparent;
    position: relative;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 15;
    box-shadow: none;
    transition: {
        property: background-color, box-shadow;
        duration: $duration;
        timing-function: $timing;
    }

    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
    }

    .identity {
        width: 230px;
        height: 50px;
        &__title {
            margin: 0;
            width: 230px;
            height: 50px;
        }
    }

    &__content-container {
        padding: 0;
        margin: 0;
        max-width: unset;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 50px;
    }

    .tools {
        &__container {
            padding: 0;
            margin: 0;
            max-width: unset;
        }

        &__content {
            display: flex;
            align-items: center;
            gap: 10px;

            & > div {
                height: 40px;
            }

            button {
                padding: 0;
                margin: 0;
                width: 40px;
                height: 40px;
                background-color: $color-white;
                border-radius: 0;
                border: none;

                svg {
                    width: 40px;
                    fill: $color-dark;
                    height: 40px;
                    transition: fill $duration $timing;
                }

                &:hover,
                &:focus {
                    svg {
                        fill: $color-main;
                    }
                }
            }
        }
    }
}

//======================================================================================================
// Header Fixed
//======================================================================================================

@keyframes headerFixed {
    from {
        top: -100px;
    }
    to {
        top: 0;
    }
}
@keyframes headerFixedAdmin {
    from {
        top: -100px;
    }
    to {
        top: 32px;
    }
}

.header--fixed {
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
    background-color: $color-white;
    top: 0;
    position: fixed;
    animation: 1s headerFixed;
    transition: 0.35s;

    & ~ main {
        margin-top: 120px;
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .header {
        height: 110px;

        &__content-container {
            gap: 83px;
        }

        .identity {
            &__title {
                width: 184px;
                height: 40px;

                svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .header--fixed ~ main {
        margin-top: 110px;
    }
}

// 960
@media screen and (max-width: $medium) {
    .header {
        &__content-container {
            gap: 30px;
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .header {
        position: relative;

        & ~ main {
            margin-top: 0;
        }

        &__container {
            justify-content: center;
        }

        &__content {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            background-color: $color-white;
            height: 60px;
            box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
            border-radius: 10px 10px 0 0;
        }

        &__content-container {
            margin: 0 auto;
            max-width: 90%;
            padding: 0 10px;
            height: 100%;
            justify-content: space-between;
        }
        .identity {
            height: 40px;
            width: 184px;
        }
    }
}
