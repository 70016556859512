.breadcrumb {
    .container {
        margin: 0;
        padding: 0;
    }

    ul {
        display: block;
        // padding: 18px 0;
    }

    li {
        position: relative;
        display: inline;
        color: $color-white;
        line-height: 1.75;
        font-family: $font-family;
        font-size: toRem(16);
        font-weight: $font-weight-normal;
        line-height: toRem(25);

        a {
            text-decoration: underline;
            text-decoration-color: transparent;
            transition: text-decoration-color $duration $timing;

            &:hover,
            &:focus {
                text-decoration-color: $color-white;
                color: $color-white;
            }
        }

        svg {
            display: inline-block;
            width: 20px;
            height: 20px;
            margin: 0 5px;
            fill: $color-white;
            vertical-align: text-top;
            margin-top: 1px;
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {
    .breadcrumb {
        .container {
            max-width: unset;
        }
        ul {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        li svg {
            margin: 0;
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .breadcrumb {
        .container {
            margin: 0 auto;
            max-width: 90%;
            padding: 0 10px;
        }

        ul {
            flex-wrap: wrap;
        }

        li svg {
            margin: 0 -1px;
        }
    }
}
