body.home {
    .footer {
        margin-top: 120px;
    }
}

.footer {
    padding: 80px 0;
    background-color: $color-main;
    overflow: hidden;

    &__container {
        @include flex($justifyContent: flex-start, $alignItems: flex-start);
        gap: 120px;
        padding-bottom: 60px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: -15px;
            right: -250px;
            z-index: 0;
            width: 400px;
            height: 405px;
            background-image: url($urlSpriteImage + "/theme-icons/blason.svg");
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    &__logo {
        @include size(150px);
        background-color: $color-third;

        svg {
            @include size(30px);
        }
    }

    &__infos,
    &__opening-hours {
        @include flex($direction: column, $justifyContent: center, $alignItems: flex-start);
        gap: 10px;
        color: $color-white;
    }

    &__infos {
        width: 250px;
    }

    &__opening-hours {
        width: 300px;
    }

    &__title {
        color: $color-white;
        font-family: $font-family--second;
        font-size: toRem(20);
        font-weight: $font-weight-bold;
        line-height: toRem(25);
        color: $color-white;
    }

    &__text {
        font-family: $font-family;
        font-size: toRem(16);
        font-weight: $font-weight-normal;
        line-height: toRem(25);
        color: $color-white;
    }

    &__phone {
        @include flex($alignItems: center);
        gap: 5px;
        font-family: $font-family;
        font-size: toRem(16);
        font-weight: $font-weight-bold;
        line-height: toRem(25);
        color: $color-white;
        text-decoration: underline;
        text-decoration-color: transparent;
        transition: text-decoration-color $duration $timing;
        margin-top: -10px;

        svg {
            @include size(26px);
            fill: $color-white;
        }

        &:hover,
        &:focus {
            color: $color-white;
            text-decoration-color: $color-white;
        }
    }

    &__links {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 15px;
        position: relative;
    }

    &__btn {
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        font-family: $font-family;
        font-size: toRem(14);
        font-weight: $font-weight-bold;
        line-height: toRem(20);
        color: $color-dark;
        letter-spacing: toRem(0.7);
        text-transform: uppercase;
        width: fit-content;
        padding: 0 25px;
        height: 50px;
        transition: {
            property: background-color, border-color, color, opacity;
            duration: $duration;
            timing-function: $timing;
        }

        svg {
            width: 20px;
            height: 20px;
            fill: $color-dark;
            transition: fill $duration $timing;
        }

        &--contact {
            background-color: $color-second;
        }

        &--labels {
            background-color: $color-light;
        }

        &:hover,
        &:focus {
            background-color: $color-dark;
            color: $color-white;

            svg {
                fill: $color-white;
            }
        }
    }

    &__menu {
        background-color: transparent;
        position: relative;

        &__list {
            @include flex($wrap: wrap, $alignItems: center, $justifyContent: space-between);
            width: 100%;
        }

        &__item {
            padding: 0;
        }

        &__link {
            font-family: $font-family;
            font-size: toRem(14);
            font-weight: $font-weight-normal;
            line-height: toRem(20);
            color: $color-white;
            text-decoration: underline;
            text-decoration-color: transparent;
            transition: text-decoration-color $duration $timing;

            &:hover,
            &:focus {
                color: $color-white;
                text-decoration-color: $color-white;
            }
        }
    }
}

body.single {
    margin-bottom: 0;

    .footer {
        margin-top: 0;
    }
}
//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    body.home {
        .footer {
            margin-top: 100px;
        }
    }
    .footer {
        &__container {
            gap: 60px;
            padding-bottom: 60px;

            &::before {
                top: 40px;
                right: -215px;
                width: 310px;
                height: 315px;
            }
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .footer {
        &__container {
            gap: 59px;
            flex-wrap: wrap;
            justify-content: center;

            &::before {
                top: unset;
                right: -140px;
                bottom: -220px;
            }
        }

        &__logo {
            flex: none;
            width: 100%;
        }

        &__infos,
        &__opening-hours {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        &__opening-hours {
            width: 100%;
            max-width: 300px;
        }

        &__text {
            text-align: center;
        }

        &__menu {
            &__list {
                justify-content: center;
                gap: 20px 40px;
            }

            &__item {
                position: relative;
            }
        }

        &__links {
            flex-direction: row;
        }
    }
}

// 640
@media screen and (max-width: $small) {
    body.home {
        .footer {
            margin-top: 80px;
        }
    }
    .footer {
        &__container {
            gap: 30px;
        }

        &__infos,
        &__opening-hours {
            flex: none;
            width: 100%;
            max-width: unset;
        }

        &__links {
            flex-wrap: wrap;
        }

        &__menu {
            &__list {
                gap: 15px 40px;
            }
        }
    }
}

@media screen and (max-width: 360px) {
    .footer {
        padding: 80px 0 100px;
    }
}
