//-------------------------------------------------------------------- //
// Document single
//-------------------------------------------------------------------- //
.card {
    &--document {
        margin-bottom: 20px;

        &__button {
            margin: 20px 0 0 0;
        }

        .accordion--transcription {
            margin: 20px 0 0 0;
        }
    }

    &__container {
        &--document {
            padding: 20px 20px;
            min-height: 0;
        }
    }

    &__content {
        &--document {
            display: block;
            color: $card--color;
        }
    }

    //=================================================================================================
    // Image par défaut
    //=================================================================================================
    .image-wrapper {
        &.documents,
        &.documents_category {
            background-image: url($urlShort + "/documents-icons/documents.svg");
            border-radius: 10px;
        }

        // border-right: 1px solid rgba(90, 151, 157, 0.5);
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .card--document {
        .accordion--transcription {
            margin-top: 25px;
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .card--document__button {
        height: fit-content;
        line-height: 1.3;
    }
}
