//======================================================================================================
// Bloc Contextual publication
//======================================================================================================
.contextual-publications {
    .grid-center {
        width: calc(100% + 30px);
    }

    // Fix bug on SMS Alert page
    .swiper-wrapper {
        height: auto;
    }

    .swiper-slide .card__container {
        &--event-v1 {
            // min-height: 260px;
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {
    .contextual-publications {
        .swiper-slide .card__container {
            &--event-v1 {
                min-height: inherit;
            }

            .card__date {
                margin-bottom: 20px;

                p {
                    flex-direction: row;

                    span {
                        font-size: toRem(16);
                        font-weight: $font-weight-semibold;
                    }
                }

                &_month {
                    margin-top: 0;
                    margin-left: 5px;
                }
            }

            .card__content {
                padding: 00px 0 0;
            }
        }
    }
}
