.socials {
    position: fixed;
    width: 40px;
    height: fit-content;
    display: block;
    top: 200px;
    right: 60px;
    z-index: 14;

    &.nav-socials {
        display: none;
    }

    &.footer-socials {
        display: block;
    }

    &--nav {
        display: none;
    }

    &__list {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 10px;

        &::before {
            content: "";
            position: absolute;
            top: -205px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 0;
            width: 2px;
            height: 205px;
            background-color: $color-dark;
        }
    }

    &__item {
        width: fit-content;
        height: fit-content;
    }

    &__link {
        width: 40px;
        height: 40px;
        display: block;
        background-color: $color-dark;
        border-radius: 50px;
        transition: background-color $duration $timing;

        svg {
            width: 40px;
            height: 40px;
            fill: $color-white;
            transition: fill $duration $timing;
        }

        &:hover,
        &:focus {
            background-color: $color-main;
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .socials {
        right: 40px;
    }
}

// 960
@media screen and (max-width: $medium) {
    body.js-open-menu {
        .socials {
            left: 30px;
        }
    }

    .socials {
        position: absolute;
        bottom: 0;
        left: 40px;
        top: unset;

        right: unset;
        top: 434px;
        left: -100vw;
        width: fit-content;
        z-index: 15;
        height: 40px;
        // opacity: 1;
        transition: {
            property: left, opacity;
            duration: $duration;
            timing-function: $timing;
        }

        &.nav-socials {
            display: block;
        }

        &.footer-socials {
            display: none;
        }

        // &.socials--nav {
        //     // left: -100vw;
        //     opacity: 0;
        //     pointer-events: none;
        //     transition: {
        //         property: left, opacity;
        //         duration: $duration;
        //         timing-function: $timing;
        //     }
        // }

        &__list {
            flex-direction: row;

            &::before {
                width: 50px;
                height: 2px;
                background-color: $color-white;
                top: 50%;
                left: -50px;
                transform: translateY(-50%);
            }
        }

        &__link {
            background-color: $color-light;

            svg {
                fill: $color-dark;
            }

            &:hover,
            &:focus {
                svg {
                    fill: $color-white;
                }
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {
}
