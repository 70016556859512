//=================================================================================================
// Display for all input fields (except buttons and checkbox)
//=================================================================================================
%forms {
    display: block;
    outline: none;
    width: 100%;
    max-width: 100%;
    background: $input-bg;
    border: $form-border;
    border-radius: $border-radius;
    line-height: 1;
}

%formspadding {
    padding: 15px;
}

//=================================================================================================
// Variable containing all input fields
//=================================================================================================
#{$forms} {
    @extend %forms;
}

#content form {
    #{$forms} {
        @extend %formspadding;
        font-size: toRem(15);
    }
}

textarea {
    height: auto;
    overflow: auto;
}

fieldset {
    margin: 0;
    padding: 0;
    border: 1px solid $border-color;
    border-radius: $border-radius;
}

legend {
    padding: 0 0.5rem;
    font-weight: $font-weight-semibold;
}

ul.acf-radio-list li input[type="checkbox"],
ul.acf-radio-list li input[type="radio"],
ul.acf-checkbox-list li input[type="checkbox"],
ul.acf-checkbox-list li input[type="radio"] {
    margin: -3px 4px 0 0 !important;
}

//=================================================================================================
// Fix issues with select
//=================================================================================================
select {
    line-height: 1;
    color: $color-text;
}

select::-ms-expand {
    display: none;
}

//=================================================================================================
// Make range full width
//=================================================================================================
[type="range"] {
    width: 100%;
}

//=================================================================================================
// Labels
//=================================================================================================
label {
    display: block;
    max-width: 100%;
    font-weight: $font-weight-semibold;
    margin: 10px 0 0;

    input {
        display: inline-block;
    }
}

//=================================================================================================
// Form validation
//=================================================================================================
input:not([type="submit"]):not([type="button"]),
select,
textarea {
    border: 1px solid $form-border-color;
    transition: border $duration $timing;

    &.has-error {
        border: 1px solid $color-error;
        box-shadow:
            inset 0 1px 1px rgba(0, 0, 0, 0.1),
            0 0 6px lighten($color-error, 35%);
    }

    &.is-success {
        border: 1px solid $color-success;
        box-shadow:
            inset 0 1px 1px rgba(0, 0, 0, 0.1),
            0 0 6px lighten($color-success, 25%);
    }

    &:hover,
    &:focus,
    &:active {
        &.has-error {
            @extend .has-error;
        }

        &.is-success {
            @extend .is-success;
        }
    }

    &:focus {
        border: 1px solid $form-border-color--focus;
    }
}

//=================================================================================================
// Placeholder Text
//=================================================================================================
::-webkit-input-placeholder,
::-moz-placeholder,
:-moz-placeholder,
:-ms-input-placeholder {
    color: $placeholder;
}

//=================================================================================================
// Gravity Forms
//=================================================================================================
.gform_wrapper {
    .gform_validation_errors {
        margin: 0 0 15px !important;
        padding: 15px 30px !important;
        background: $color-bg-error !important;
        border: 0 !important;
        border-left: 6px solid !important;
        border-color: $color-error !important;
        border-radius: 0 !important;
        box-shadow: unset !important;
        text-align: left !important;

        h2 {
            margin: 0;
            padding: 0;
            font-size: $font-size--text;
            font-style: normal;
            font-weight: $font-weight-bold;
            color: $color-error;
            line-height: $line-height;

            &::before {
                display: none;
            }
        }
    }

    &.gravity-theme .gfield_required {
        font-size: toRem(19) !important;
    }

    form {
        input:not([type="submit"]):not([type="button"]),
        select,
        textarea {
            border: 1px solid $form-border-color;
            transition: {
                property: border, border-color;
                duration: $duration;
                timing-function: $timing;
            }

            &:focus {
                border: 1px solid $form-border-color--focus;
            }
        }

        select {
            padding: 15px !important;
        }

        .description_above {
            .gfield_description {
                font-size: $font-size--text;
                color: $color-text--light;
                font-style: italic;
            }
        }

        .gsection {
            border-bottom: 0;
            padding-bottom: 0;
            margin-bottom: 0;
            margin-top: 30px !important;
        }

        h2.gsection_title {
            margin: inherit !important;
            padding-bottom: 10px !important;
        }

        li.gfield {
            margin-right: 0;
            padding-right: 0 !important;

            &.gfield_error {
                padding-bottom: 0;
                padding-top: 0;
                background: inherit;
                border: inherit;
                border-top: 0;
                border-bottom: 0;

                .gfield_label,
                div.ginput_complex.ginput_container label {
                    color: inherit;
                }

                input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not(
                        [type="image"]
                    ):not([type="file"]),
                textarea {
                    border-color: $color-gray;
                }
            }

            label {
                font-weight: $font-weight;
            }

            & > label {
                font-weight: $font-weight-bold;
            }

            .ginput_container_fileupload .validation_message {
                display: none;
            }
        }

        ul.gfield_checkbox li,
        ul.gfield_radio li {
            display: flex;
            align-items: baseline;
            padding: 0 !important;
        }

        ul.gfield_checkbox li input {
            margin-right: 10px;
        }

        .validation_message,
        .validation_error,
        .acf-error-file {
            margin: 5px 0 15px;
            padding: 10px 20px !important;
            background: $color-bg-error;
            border: 0 !important;
            border-left: 6px solid $color-error !important;
            font-size: $font-size--text !important;
            font-style: normal !important;
            font-weight: $font-weight-bold;
            color: $color-error !important;
            line-height: $line-height;
        }
    }

    .gform_body {
        .top_label div.ginput_container {
            position: relative;
        }

        // Hours
        .gfield_time_hour i {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 62px;
            margin: 0 0 30px 0;
        }
        .field_sublabel_above .gfield_time_hour i {
            margin: 30px 0 0 0;
        }
        .field_sublabel_below .gfield_time_hour i {
            margin: 0 0 30px 0;
        }
        .field_sublabel_hidden_label .gfield_time_hour i {
            margin: 0;
        }
    }

    // Files
    input.button.gform_button_select_files {
        margin: 0;
    }
}

.bloc-content--form {
    padding: 0;
}

.rgpd-container {
    display: flex;
    align-items: center;
    margin-top: 20px;
    gap: 4px;

    input {
        flex: none;
        margin-right: 10px;
        margin-bottom: 0 !important;
        align-self: unset !important;
    }

    label {
        margin: 5px 0;
    }
}

.gform_footer.top_label {
    display: block !important;
}

.submit-container {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin: 20px 0 0;

    .gpoll_summary {
        width: 100%;

        .gpoll_wrapper {
            .gpoll_ratio_box {
                background: $color-main;
            }
            .gpoll_bar_juice {
                background-color: $color-main;
                background-image: none;
            }
        }
    }

    .gpoll_button,
    .gpoll_back_button {
        display: inline-flex;
        align-items: center;
        margin: 5px 14px 5px 0;
        padding: $btn-second-padding;
        background: $btn-second-bg;
        border-radius: $btn-border-radius;
        border: $btn-border-width $btn-border-style $btn-second-border-color;
        font-family: $btn-font-family;
        font-weight: $btn-font-weight;
        font-size: $btn-font-size;
        line-height: $btn-line-height;
        text-transform: $btn-text-transform;
        text-align: center;
        color: $btn-second-color;

        &:hover,
        &:focus,
        &:active,
        &.is-active,
        &.is-checked {
            background: $btn-second-bg--hover;
            border-color: $btn-second-border-color--hover;
            color: $btn-second-color--hover;
        }
    }

    .gpoll_button {
        margin: 5px 0 8px 14px;
    }
}

button[disabled],
input[type="submit"][disabled] {
    pointer-events: none;
    opacity: 0.8;
}

#rgpd-link {
    font-weight: $font-weight;
    color: $color-link;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

.required {
    color: $color-error;
}

.gform_wrapper form .gform_footer input.button,
.gform_wrapper form .gform_footer input[type="submit"],
.gform_wrapper form .gform_page_footer input.button,
.gform_wrapper form .gform_page_footer input[type="submit"] {
    font-size: $btn-font-size;
}

.gform_wrapper form {
    .gfield_html_formatted {
        a {
            display: inline-block;
            text-decoration: underline;
            color: $color-link;

            &[target="_blank"]::after {
                content: "\f504";
                font: normal 16px/1 dashicons;
                position: relative;
                float: right;
                top: 2px;
                margin-left: 2px;
            }

            &:hover,
            &:active,
            &:focus {
                text-decoration: none;
                color: $color-link--hover;
            }
        }

        ul,
        ol {
            line-height: 22px;
            padding-left: 30px;
        }

        ul {
            li {
                display: block;
                position: relative;

                &::before {
                    content: "" !important;
                    position: absolute !important;
                    top: 5px;
                    left: -20px;
                    display: flex !important;
                    width: 10px;
                    height: 10px;
                    background-color: $color-main;
                    border-radius: 50%;
                }

                ul {
                    li {
                        &::before {
                            background-color: $color-gray;
                        }

                        ul {
                            li {
                                &::before {
                                    background-color: $color-second;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.gform_wrapper .gform_legacy_markup_wrapper {
    .gf_page_steps {
        margin: 0 0 20px 0;
        padding: 0 0 4px 0;
    }

    .gform_page_footer {
        margin: 20px 0 14px 0;
        padding: 20px 0 0 0;
    }

    h1,
    h2,
    h3 {
        margin-top: 20px;
        font-weight: $font-weight--heading;
    }
}

//=================================================================================================
// Fix select grid- maj plugin GF 08/2023
//=================================================================================================
.gform-body {
    [class*="grid-"],
    [class*="grid_"],
    [class~="grid"] {
        display: unset;
        flex-flow: unset;
        margin: 0;

        @media screen and (max-width: $large) {
            margin: 0;
        }
    }
}

.gform_wrapper {
    &.gravity-theme {
        .ginput_container_time {
            max-width: 80px !important;
            min-width: 80px !important;
        }
    }
}

.gform_legacy_markup_wrapper div.ginput_complex.ginput_container {
    display: inherit;
}

// Style tous les templates 21/11/2023
.gform_wrapper.gravity-theme .gform_fields {
    grid-row-gap: 30px !important;
}
.gform_wrapper.gravity-theme .gfield_label {
    margin-bottom: 5px !important;
}
.gform_wrapper.gravity-theme .gfield_header_item,
.gform_wrapper.gravity-theme .gform_fileupload_rules,
.gform_wrapper.gravity-theme .ginput_complex label {
    padding-bottom: 5px !important;
}

// Autre fix 11/2023 à vérifier !
.gform_wrapper .gform_body .top_label div.ginput_container.ginput_container_consent {
    display: flex;
    align-items: center;
    gap: 10px;

    label {
        margin-top: 0;
    }
}

.gform_wrapper.gravity-theme .field_description_above .description,
.gform_wrapper.gravity-theme .field_description_above .gfield_description,
.gform_wrapper.gravity-theme .field_description_above .gsection_description,
.gform_wrapper.gravity-theme .field_description_above .instruction {
    margin-bottom: 5px !important;
}

.gform_legacy_markup_wrapper img.ui-datepicker-trigger {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
}

.gform_wrapper .gform_body .gfield_time_hour i {
    left: 70px;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 !important;
}
.gform_legacy_markup_wrapper .top_label .gsection_description {
    margin-top: 5px !important;
}

.gform_wrapper input[type="radio"],
.gform_wrapper input[type="checkbox"] {
    margin: 0 5px 0 0;
}

.gform_wrapper.gravity-theme .gfield-choice-input + label {
    vertical-align: bottom !important;
}

//=================================================================================================
// ACF Forms
//=================================================================================================
form {
    .acf-fields {
        .acf-field,
        .acf-field:first-child {
            padding: 0;
            margin-top: 30px;
            border: 0;

            .acf-label {
                margin: 0 0 5px;

                label {
                    font-weight: $font-weight-bold;
                }

                .description {
                    margin-top: 5px;
                    color: $color-text--light;
                    font-style: italic;
                    line-height: 1.2;
                    font-size: $font-size--text;
                }
            }

            .acf-image-uploader::before,
            .acf-file-uploader::before {
                content: "En nous faisant parvenir une image, vous certifiez en être le propriétaire et vous nous autorisez à l'utiliser sur le site.";
                position: relative;
                display: block;
                margin: 0 0 10px;
                color: $color-text--light;
                font-style: italic;
            }

            .acf-file-uploader::before {
                content: "En nous faisant parvenir un média, vous certifiez en être le propriétaire et vous nous autorisez à l'utiliser sur le site.";
            }

            .acf-error-file {
                p {
                    margin-top: 10px;
                    font-weight: $font-weight-bold;
                    color: $color-error;
                    font-size: $font-size--text-small;

                    &::before {
                        content: "\f348";
                        font: normal 15px/1 dashicons;
                        position: relative;
                        top: 3px;
                        margin-right: 3px;
                    }
                }
            }

            .acf-fields {
                border: 0;

                .acf-field[data-name="top_post_images"] > .acf-label {
                    display: none;
                }

                &.acf-fields-names {
                    display: flex;
                    justify-content: space-between;

                    .acf-field {
                        margin-top: 0;
                        width: calc(50% - 15px);
                    }
                }
            }

            // Consultations
            &.profile {
                margin: 0 0 30px;
            }

            &[data-name="primary_image"] .acf-label::after,
            &[data-name="document"] .acf-label::after,
            &[data-name="logo"] .acf-label::after,
            &[data-name="image"] .acf-label::after {
                content: "Poids maximal autorisé : 1 Mo";
                position: relative;
                display: block;
                margin: 10px 0 0;
                color: $color-text--light;
                font-style: italic;
            }

            &[data-type="message"] {
                display: none;
            }

            &[data-name="document"] .acf-label::after {
                content: "Poids maximal autorisé : 25 Mo";
            }

            &[data-name="other_lang"] {
                margin-top: 0;

                .acf-label {
                    display: inline-block;
                    margin: 10px 0;

                    label {
                        position: absolute;
                        right: -15px;
                    }
                    .description {
                        display: inline-block;
                    }
                }
            }

            &[data-name="file_dating_group"] {
                margin-top: 0;
                margin-bottom: 30px;

                & > .acf-label {
                    display: none;
                }

                & > .acf-input > .acf-fields > .acf-field {
                    margin-top: 0;
                    margin-right: 30px;
                    width: calc(33.33% - 20px) !important;
                    border: 0;

                    &:last-child {
                        margin-right: 0;
                    }

                    label {
                        margin-top: 5px;
                        font-weight: $font-weight;
                    }
                }
            }

            &[data-name="url"] {
                .acf-icon {
                    display: none;
                }

                input {
                    padding-left: 15px !important;
                }
            }

            &[data-name="text_transcription"] {
                margin-top: 0;

                & > .acf-label label {
                    margin: 10px 0;
                    font-weight: $font-weight;
                }
            }

            &.rgpd > label {
                font-weight: $font-weight;
            }

            .acf-notice {
                border-width: 0 !important;
                border-left-width: 6px !important;

                p {
                    margin: 0;
                    padding: 7px 0;
                    font-size: $font-size--text;
                    font-weight: $font-weight-bold;
                }
            }

            &[data-name="event_detail_group"] {
                & > .acf-label {
                    display: none;
                }

                .acf-field {
                    &[data-name="event_date_group"] {
                        & > .acf-label {
                            display: none;
                        }

                        .acf-field {
                            &[data-name="dates"] {
                                position: relative;
                                padding-bottom: 40px;

                                & > .acf-label {
                                    position: absolute;
                                    bottom: 0;
                                }

                                .acf-fields {
                                    display: flex;
                                    justify-content: space-between;

                                    .acf-field {
                                        width: calc(50% - 15px);
                                    }
                                }
                            }

                            &[data-name="event_recurrence"] {
                                display: none;
                            }
                        }

                        .acf-fields {
                            .acf-field {
                                margin-top: 0;
                            }
                        }
                    }

                    &[data-name="event_hours_group"] {
                        margin-top: 0;

                        & > .acf-label {
                            display: none;
                        }

                        .acf-fields {
                            display: flex;
                            justify-content: space-between;

                            .acf-field {
                                width: calc(50% - 15px);
                            }
                        }
                    }

                    &[data-name="location_group"] {
                        .acf-field {
                            &[data-name="additional_address"] {
                                margin-top: 0;

                                & > .acf-label {
                                    display: none;
                                }
                            }
                        }
                    }

                    &[data-name="tariff_group"] {
                        .acf-field {
                            &[data-name="tarifs"] {
                                margin-top: 0;
                            }
                        }
                    }

                    &[data-name="event_accessibility_group"] {
                        .acf-field {
                            &[data-name="accessibility"] {
                                margin-top: 10px;

                                .acf-checkbox-list {
                                    li {
                                        label {
                                            font-size: $font-size--text;
                                            font-weight: $font-weight;
                                        }

                                        &:first-child {
                                            label {
                                                font-weight: $font-weight-bold;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &[data-name="contact_detail_group"] {
                & > .acf-label {
                    display: none;
                }

                .acf-field[data-name="informations_group"] {
                    margin-top: 10px;

                    .acf-field[data-name="support_choice"] {
                        .acf-button-group {
                            label {
                                background: $tabs-bg;
                                border: $tabs-border;
                                border-color: $tabs-border-color;
                                color: $tabs-color;
                                transition: {
                                    property: background, border-color, color;
                                    duration: $duration;
                                    timing-function: $timing;
                                }

                                &.selected,
                                &:hover,
                                &:focus,
                                &:active {
                                    cursor: pointer;
                                    background: $tabs-bg--active;
                                    border-color: $tabs-border-color--active;
                                    color: $tabs-color--active;
                                }
                            }
                        }
                    }
                }

                .acf-field[data-name="exclude_siret"],
                .acf-field[data-name="exclude_rna"] {
                    margin-top: 10px;

                    ul {
                        li {
                            label {
                                display: inline-flex;
                                align-items: center;
                                margin: 0;
                                font-size: $font-size--text;
                                font-weight: $font-weight;

                                input[type="checkbox"] {
                                    margin-right: 10px;
                                }
                            }
                        }
                    }
                }

                .acf-input-prepend {
                    height: 52px;
                    padding: 15px;
                    border-color: $form-border-color;
                    border-radius: $border-radius 0 0 $border-radius;
                    background-color: $color-bg--neutral;
                }

                .acf-input-wrap {
                    input {
                        height: 52px;
                    }

                    .acf-is-prepended {
                        border-radius: 0 $border-radius $border-radius 0 !important;
                    }
                }
            }

            &[data-name="documents_group"] {
                .acf-field-button-group {
                    .acf-button-group {
                        label {
                            background: $tabs-bg;
                            border: $tabs-border;
                            border-color: $tabs-border-color;
                            color: $tabs-color;
                            transition: {
                                property: background, border-color, color;
                                duration: $duration;
                                timing-function: $timing;
                            }

                            &.selected,
                            &:hover,
                            &:focus,
                            &:active {
                                cursor: pointer;
                                background: $tabs-bg--active;
                                border-color: $tabs-border-color--active;
                                color: $tabs-color--active;
                            }
                        }
                    }
                }
            }
        }

        .acf-date-picker:before {
            content: "";
            @include absolute($top: 50%, $right: 10px);
            transform: translateY(-50%);
            @include size(30px);
            mask-position: center;
            mask-size: contain;
            mask-repeat: no-repeat;
            mask-image: url($urlShort + "ic-misc-calendar.svg");
            background-color: $form-color-icon;
            pointer-events: none;
        }

        .acf-time-picker:before {
            content: "";
            @include absolute($top: 50%, $right: 10px);
            transform: translateY(-50%);
            @include size(30px);
            mask-position: center;
            mask-size: contain;
            mask-repeat: no-repeat;
            mask-image: url($urlShort + "ic-misc-time.svg");
            background-color: $form-color-icon;
            pointer-events: none;
        }

        & > .acf-notice {
            margin-top: 20px;
        }

        &::after {
            display: none;
        }
    }

    .acf-notice,
    .acf-notice {
        margin: 0 0 15px;
        padding: 3px 12px 3px 25px;
        border: 1px solid transparent !important;
        border-left-width: 6px !important;
    }

    .acf-notice.-success {
        border-left-color: $color-success !important;
    }
    .acf-notice.-warning {
        border-left-color: $color-alert !important;
    }
    .acf-notice.-error {
        border-left-color: $color-error !important;
    }

    .acf-field .acf-notice.-success {
        background: $color-bg-success;
        color: $color-success;
    }
    .acf-field .acf-notice.-warning {
        background: $color-bg-alert;
        color: $color-alert;
    }
    .acf-field .acf-notice.-error {
        background: $color-bg-error;
        color: $color-error;
    }

    .acf-notice p {
        font-size: $font-size--text;
        line-height: 1.4;
    }

    .acf-notice .acf-notice-dismiss {
        display: flex;
        justify-content: center;
        align-items: center;
        top: 12px;
        background: $color-white !important;
        opacity: 1;
        font-size: $font-size--text-medium;
    }

    .acf-notice.-success .acf-notice-dismiss {
        color: $color-success !important;
    }
    .acf-notice.-warning .acf-notice-dismiss {
        color: $color-alert !important;
    }
    .acf-notice.-error .acf-notice-dismiss {
        color: $color-error !important;
    }
    .acf-required {
        color: #f00;
    }

    .buttons-container {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;

        &--rgpd {
            justify-content: flex-end;
        }

        [type="submit"] {
            margin-right: 0;
        }
    }

    // Consultations
    .acf-fields-address {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .acf-field {
            width: calc(50% - 15px);

            &.address {
                width: 100%;
            }
        }
    }

    .acf-fields-cgu {
        display: flex;
        align-items: center;
        column-gap: 10px;
        margin-top: 30px;

        label {
            font-weight: $font-weight-normal;
            margin-top: 0;

            a {
                text-decoration: underline;

                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }
        }
    }
}

// Fix 22/11/2021 button solo form on left
#rgpd-form .buttons-container--rgpd {
    justify-content: flex-start;
}

/*** PASSWORD FORM ***/
.password-description {
    margin-top: 30px;
}

.post-password-form {
    .post_password {
        max-width: 300px;
        margin: 5px 0 20px;
    }
}

//======================================================================================================
// Form page / etape
//======================================================================================================

.container .gform_wrapper {
    .gf_progressbar {
        background: $color-bg--neutral;
        border-radius: $border-radius;

        .percentbar_blue {
            position: relative;
            z-index: 1;
            background: $color-dark;
            color: $color-white;
            border-radius: $border-radius;
        }

        &::after {
            background-color: rgba(0, 0, 0, 0.1);
            border-radius: $border-radius;
        }
    }

    // Step page
    .gf_step {
        opacity: 0.6;
    }

    .gf_progressbar_wrapper h3.gf_progressbar_title {
        font-size: $font-size--text-small;
        opacity: 1;
        color: $color-text--light;
    }
}

//=================================================================================================
//  Comments form
//=================================================================================================
.comments {
    &__title {
        margin-bottom: 10px;
    }

    .comment-form {
        label {
            margin: 5px 0;
        }

        > p {
            margin-top: 25px;
        }

        .description {
            display: block;
            font-style: italic;
            color: $color-text--light;
            margin: 0;
        }

        &-cookies-consent {
            display: flex;
            align-items: center;
            margin-top: 20px;

            input {
                margin-right: 10px;
            }
        }

        .form-submit {
            display: flex;
            justify-content: flex-start;

            input {
                margin: 25px 0;
            }
        }

        .logged-in-as {
            font-style: italic;
            color: $color-text--light;

            a {
                text-decoration: underline;
            }
        }
    }

    .comment-reply-title {
        #cancel-comment-reply-link {
            font-weight: $font-weight-bold;
            font-size: $font-size--text-small;
            line-height: 1rem;
            text-transform: uppercase;
            color: $color-text--light;
        }
    }

    .commentlist {
        .comment-meta {
            font-size: $font-size--text-small;
            color: $color-text--light;
            order: 1;
        }

        .comment-author {
            order: 2;
            margin-bottom: 10px;

            .fn {
                color: $color-dark;
                font-size: $font-size--5;
                font-weight: $font-weight-bold;
                font-style: normal;
            }

            .says {
                color: $color-text--light;
            }
        }

        .comment-body {
            position: relative;
            display: flex;
            flex-direction: column;
            margin-top: 30px;
            padding-bottom: 40px;

            > p {
                order: 5;
            }

            &--moderation {
                flex-direction: column-reverse;

                .comment-meta {
                    order: 3;
                }

                .comment-author {
                    margin-bottom: 0;
                }
            }
        }

        ul.children {
            margin-left: 30px;

            .comment-body {
                padding: 10px 0 45px 20px;
                box-sizing: border-box;
                box-shadow: -3px 0px 0px $color-gray;
            }

            > li {
                > ul.children {
                    .comment-body {
                        padding: 10px 0 10px 20px;
                        box-shadow: -3px 0 0 $color-third;
                    }
                }
            }
        }

        .reply {
            position: absolute;
            bottom: 5px;
            color: $color-text--light;
            text-decoration: underline;
        }

        .comment-awaiting-moderation {
            color: $color-text--light;
            order: 1;
            margin-bottom: 20px;
        }
    }

    &__nav {
        display: flex;
        justify-content: space-between;

        &-top {
            .comments {
                &__nav-left,
                &__nav-right {
                    a {
                        margin-top: 30px;
                        margin-bottom: 15px;
                    }
                }
            }
        }

        &-bottom {
            .comments {
                &__nav-left,
                &__nav-right {
                    a {
                        margin-top: 50px;
                    }
                }
            }
        }

        &-left,
        &-right {
            display: flex;
            align-items: center;

            a {
                display: flex;
                align-items: center;
                font-weight: $font-weight-bold;
                font-size: $font-size--text-small;
                text-transform: uppercase;
                color: $color-text--light;

                &:hover {
                    color: $color-dark;

                    svg {
                        fill: $color-dark;
                    }
                }
            }

            svg {
                height: 26px;
                width: 26px;
                fill: $color-text--light;
                transition: $duration;
            }
        }

        &-left {
            svg {
                margin-right: 8px;
            }
        }

        &-right {
            svg {
                transform: rotate(180deg);
                margin-left: 8px;
            }
        }
    }
}

// Adapt form page style
.content-wrapper .gform_wrapper {
    .gform_validation_errors > h2.hide_summary {
        display: flex;
        align-items: center;
        padding-bottom: 0;
        border: 0;

        .gform-icon {
            position: relative;
            left: auto;
            margin-right: 5px;
        }
    }

    &.gravity-theme {
        .gf_progressbar_blue {
            background: $color-light;
            border-radius: $border-radius;
        }

        .gf_progressbar_percentage.percentbar_blue {
            background: $color-dark;
            color: $color-white;
            font-weight: $font-weight-bold;
            border-radius: $border-radius 0 0 $border-radius;

            &.percentbar_100 {
                border-radius: $border-radius;
            }
        }

        .gfield-choice-input + label {
            vertical-align: unset;
        }

        .gsection_title {
            margin: 0;
        }

        .gform_previous_button.button,
        .gform_save_link.button {
            background-color: $btn-second-bg;
            border-color: $btn-second-border-color;
            color: $btn-second-color;

            &:hover,
            &:focus {
                background-color: $btn-second-bg--hover;
                border-color: $btn-second-border-color--hover;
                color: $btn-second-color--hover;
            }
        }

        select {
            padding: 14px 15px !important;
        }

        .ginput_complex {
            align-items: center;
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 940
@media screen and (max-width: $medium) {
    form
        .acf-fields
        .acf-field[data-name="event_detail_group"]
        .acf-field[data-name="event_date_group"]
        .acf-field[data-name="dates"] {
        padding-bottom: 50px;
    }

    form .acf-fields .acf-field .acf-fields.acf-fields-names,
    form .acf-fields .acf-field:first-child .acf-fields.acf-fields-names {
        flex-wrap: wrap;

        .acf-field {
            margin-top: 30px;
            width: 100%;
        }
    }

    // Consultations
    form {
        .acf-fields {
            .acf-field,
            .acf-field:first-child {
                // Consultations
                &.profile {
                    margin: 30px 0 0;
                }
            }
        }

        .acf-fields-address {
            .acf-field {
                width: 100%;
            }
        }
    }

    // form contact
    .bloc-content--form .gfield {
        input.medium,
        select.medium {
            width: 100% !important;
        }
    }
}

// 640
@media screen and (max-width: $small) {
    form .acf-fields .acf-field:first-child[data-name="file_dating_group"] > .acf-input > .acf-fields > .acf-field,
    form .acf-fields .acf-field[data-name="file_dating_group"] > .acf-input > .acf-fields > .acf-field {
        width: 100% !important;
    }

    form
        .acf-fields
        .acf-field[data-name="event_detail_group"]
        .acf-field[data-name="event_date_group"]
        .acf-field[data-name="dates"] {
        padding-bottom: 70px;
    }

    form
        .acf-fields
        .acf-field:first-child[data-name="event_detail_group"]
        .acf-field[data-name="event_date_group"]
        .acf-field[data-name="dates"]
        .acf-fields,
    form
        .acf-fields
        .acf-field[data-name="event_detail_group"]
        .acf-field[data-name="event_date_group"]
        .acf-field[data-name="dates"]
        .acf-fields {
        flex-wrap: wrap;

        .acf-field {
            margin-top: 30px;
            width: 100%;
        }
    }

    form .buttons-container {
        flex-direction: column-reverse;

        a,
        button {
            width: 100%;
            margin: 5px 0;
        }
    }

    .comments {
        .commentlist {
            .children {
                .comment-body {
                    padding: 10px 0 10px 20px;
                }
            }
        }
    }
}
