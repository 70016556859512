.nav-main {
    // position: relative;
    z-index: 10;
    background-color: transparent;

    &::before {
        content: "";
        position: absolute;
        top: 120px;
        left: 0px;
        z-index: 0;
        width: 100vw;
        opacity: 0;
        pointer-events: none;
        background-color: $color-dark;
        transition: {
            property: opacity;
            duration: $duration;
            timing-function: $timing;
        }
    }

    &.js-open-submenu {
        &::before {
            pointer-events: all;
            opacity: 1;
        }
    }

    &.small {
        &::before {
            height: 290px;
        }
    }

    &.medium {
        &::before {
            height: 345px;
        }
    }

    &.big {
        &::before {
            height: 400px;
        }
    }

    // Container menu
    &__container {
        @include flex($justifyContent: space-between, $alignItems: center);
        margin: 0;
        padding: 0;
        max-width: unset;
    }

    // Main menu
    .menu {
        @include flex($wrap: wrap);
        gap: 50px;

        &__item {
            &.menu-item-has-children.js-open-submenu {
                .submenu {
                    opacity: 1;
                    // visibility: visible;
                    pointer-events: inherit;

                    // transition: none;
                }

                .menu__link {
                    color: $color-main;

                    &::before {
                        bottom: -61px;
                        opacity: 1;
                    }
                }
            }

            &:last-child {
                .menu__link {
                    &:after {
                        @include size(0);
                        background-color: $color-bg--transparent;
                    }
                }
            }
        }

        &__link {
            position: relative;
            @include flex($alignItems: center);
            padding: 0;
            background-color: $color-bg--transparent;
            color: $color-dark;
            text-transform: uppercase;
            font-family: $font-family;
            font-size: toRem(20);
            font-weight: $font-weight-semibold;
            line-height: toRem(20);
            letter-spacing: toRem(1);
            transition: {
                property: background-color, color;
                duration: $duration;
                timing-function: $timing;
            }

            &::before {
                content: "";
                @include absolute($right: 0, $bottom: -70px, $left: 0);
                @include size(21px);
                border-radius: 4px 0 0 0;
                transform: rotate(45deg);
                clip-path: polygon(0 0, 0% 100%, 100% 0);
                opacity: 0;
                margin: 0 auto;
                background-color: $color-dark;
                transition: {
                    property: bottom, opacity;
                    duration: $duration;
                    timing-function: $timing;
                }
            }

            &:hover,
            &:focus {
                color: $color-main;

                // &::before {
                //     bottom: -61px;
                //     opacity: 1;
                // }
            }
        }
    }

    // Sub menu
    .submenu {
        @include flex($justifyContent: center);
        position: absolute;
        right: 0;
        top: 120px;
        z-index: 1;
        width: 100%;
        padding: 60px 15px 50px 15px;
        min-height: 287px;
        // background-color: $color-dark;
        box-shadow: $shadow;
        // visibility: hidden;
        opacity: 0;
        pointer-events: none;
        transition: {
            property: visibility, opacity, pointer-events;
            duration: $duration;
            timing-function: $timing;
        }

        &__nav {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 0 30px;
            height: fit-content;
        }

        &__item {
            display: inline-flex;
            width: 100%;
            margin: -1px; // Fix space beetween inline-flex
            padding: 0;
            line-height: initial;
            height: fit-content;
        }

        &__link {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            height: 60px;
            padding: 10px 30px 10px 0;
            font-family: $font-family;
            font-size: toRem(16);
            font-weight: $font-weight-bold;
            line-height: toRem(25);
            color: $color-white;

            &::after {
                content: "\f345";
                font: normal 20px/1 dashicons;
                position: absolute;
                right: 0;
                color: rgba($color-white, 0.5);
                width: 26px;
                height: 26px;
                border-radius: $border-radius--round;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: {
                    property: background-color, border-color, color, opacity;
                    duration: $duration;
                    timing-function: $timing;
                }
            }

            &:hover,
            &:focus {
                &::after {
                    color: $color-text;
                    background-color: $color-second;
                }
            }

            &--grandchild {
                display: flex;
                padding: 10px 10px 10px 15px;
                border-bottom: 0;
                font-weight: $font-weight-normal;

                &::before {
                    display: none;
                }

                &::after {
                    display: none;
                }
            }
        }

        &__close {
            @include absolute($top: 13px);
            @extend %SpaceFull-to-Container--right;
            margin: 0;
            padding: 0;
            background-color: $color-bg--transparent;
            font-family: $font-family;
            font-size: toRem(16);
            font-weight: $font-weight-semibold;
            line-height: toRem(25);
            color: $color-white;
            text-transform: uppercase;
            text-decoration: underline;
            text-decoration-color: transparent;
            transition: text-decoration-color $duration $timing;

            &__icon {
                display: block;
                @include size(30px);
                fill: $color-white;
            }

            &__mobile {
                display: none;
            }

            &:hover,
            &:focus {
                text-decoration-color: $color-white;

                svg {
                    fill: $color-white;
                }
            }
        }
    }

    // For responsive
    &__button,
    &__icon,
    &__close {
        display: none;
    }
}

//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {
    .nav-main {
        &::before {
            top: 110px;
        }

        .menu {
            gap: 38px;

            .menu__item.menu-item-has-children.js-open-submenu {
                .menu__link::before {
                    bottom: -56px;
                }
            }

            &__link {
                font-size: toRem(16);
                line-height: toRem(20);

                &:hover,
                &:focus {
                    &::before {
                        bottom: -56px;
                    }
                }
            }
        }

        .submenu {
            top: 110px;
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    // if menu open (no scroll on body)
    body.js-open-menu {
        overflow: hidden;
    }

    .nav-main {
        position: relative;

        // If menu open
        &.js-open-menu {
            // Container menu
            .nav-main__container {
                left: 0;
            }
        }

        // Container menu
        &__container {
            position: fixed;
            top: 0;
            left: -100vw;
            right: 100%;
            z-index: 990;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            row-gap: 15px;
            max-width: inherit;
            width: 100%;
            height: 100vh;
            background-color: $color-dark;
            padding: 40px 0;
            transition: left $duration $timing;
        }

        // Burger menu responsive - Open menu
        &__button {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            margin: 0;
            padding: 0px 25px 0px;
            background-color: $color-second;
            border: 0;
            border-radius: 50px;
            font-family: $font-family;
            font-size: toRem(16);
            font-weight: $font-weight-bold;
            line-height: toRem(20);
            color: $color-dark;
            letter-spacing: toRem(0.7);
            text-transform: uppercase;
            cursor: pointer;

            svg {
                fill: $color-dark;
                transition: fill $duration $timing;
                width: 20px;
                height: 20px;
            }

            &:hover,
            &:focus {
                color: $color-white;
                background-color: $color-dark;

                svg {
                    fill: $color-white;
                }
            }
        }

        // Button icon (Burger menu responsive - Open menu)
        &__icon {
            position: relative;
            top: -1px;
            display: block;
            width: 20px;
            height: 2px;
            background-color: $color-white;

            &:before,
            &:after {
                content: "";
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                background-color: $color-white;
            }

            &:before {
                top: 7px;
            }

            &:after {
                top: -7px;
            }
        }

        // Burger menu responsive - Close menu
        &__close {
            position: relative;
            z-index: 10;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            min-height: 40px;
            margin: 0 40px 0 auto;
            padding: 10px;
            background-color: $color-white;
            border: $btn-border-width $btn-border-style $color-white;
            border-radius: $border-radius--round;

            svg {
                width: 12px;
                height: 12px;
                margin: 0;
                fill: $color-dark;
            }

            &:hover,
            &:focus {
                background-color: $color-white;
                border-color: $color-white;

                svg {
                    fill: $color-dark;
                }
            }
        }

        // Main menu
        .menu {
            // position: relative;
            padding-bottom: 100px;

            flex-direction: column;
            flex-wrap: nowrap;
            // overflow-y: auto;
            width: 100%;
            padding: 0 40px;
            margin-top: 20px;
            gap: 46px;

            &__item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100% !important;
                height: fit-content;

                &.menu-item-has-children {
                    &::after {
                        content: "\f345";
                        font: normal 20px/1 dashicons;
                        color: $color-second;
                        transition: none;
                    }

                    // If sub menu open
                    &.js-open-submenu {
                        .submenu {
                            left: 0;
                            transition: left 0.3s ease-in-out;
                        }

                        .menu__link {
                            background-color: $color-bg--transparent;
                            color: $color-white;
                        }
                    }
                }

                &:hover,
                &:focus {
                    .menu__link {
                        background-color: $color-bg--transparent;
                        color: $color-white;
                    }
                }
            }

            &__link {
                justify-content: flex-start !important;
                max-width: inherit;
                width: 100%;
                height: auto;
                padding: 0;
                color: $color-white;
                transition: none;
                font-size: toRem(20);
                line-height: toRem(20);
                letter-spacing: toRem(1);

                &::after {
                    display: none;
                }

                &:hover,
                &:focus {
                    background-color: $color-bg--transparent;
                    color: $color-white;
                }
            }
        }

        // Sub menu
        .submenu {
            left: -100vw;
            top: 0;
            // right: 100%;
            z-index: 16;
            @include flex($direction: column, $justifyContent: flex-start);
            row-gap: 30px;
            @include size(100vw, 100vh);
            padding: 40px 0 60px 0;
            background-color: $color-dark;
            transition: {
                property: visibility, pointer-events, left;
                duration: $duration;
                timing-function: $timing;
            }
            opacity: 1;

            &::after {
                display: none;
            }

            // Burger menu responsive - Close submenu
            &__close {
                position: static;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                width: 40px;
                height: 40px;
                margin: 0 0 0 40px;
                padding: 0;
                background-color: transparent;
                border: $btn-border-width $btn-border-style $color-white;
                border-radius: $border-radius--round;
                order: 1;
                transition: background-color $duration $timing;

                &:hover,
                &:focus {
                    background-color: $color-white;
                    border-color: $color-white;

                    svg {
                        fill: $color-dark;
                    }
                }

                &__mobile {
                    display: block;
                    width: 40px;
                    height: 40px;
                    fill: $color-white;
                    transition: fill $duration $timing;
                }

                &__icon,
                span {
                    display: none;
                }
            }

            &__nav {
                display: flex;
                flex-direction: column;
                max-width: 100%;
                padding: 0 40px;
                overflow-y: auto;
                order: 2;
                gap: 0;
            }

            &__item {
                margin: auto;
                height: fit-content;
            }

            &__link {
                color: $color-white;
                padding: 12.5px 25px 12.5px 0;
                height: fit-content;
                font-size: toRem(16);
                line-height: toRem(25);

                // &::after {
                //     display: none;
                // }
            }
        }
    }

    // Admin
    .admin-bar {
        .nav-main {
            &__container,
            .submenu {
                padding-top: 70px;
            }
        }
    }
}
