.front-page {
    &__title {
        font-family: $font-family--second;
        font-size: toRem(45);
        font-weight: $font-weight-bold;
        line-height: toRem(50);
        color: $color-dark;

        &::before {
            display: none;
        }
    }
    // ==================================
    // Slideshow
    // ==================================
    .slideshow {
        position: relative;
        &::before {
            content: "";
            position: absolute;
            top: 0px;
            right: 50%;
            z-index: -1;
            width: 50vw;
            height: 100%;
            background-color: $color-main;
        }

        .swiper-container {
            background-color: $color-main;
            border-radius: 0px $border-radius $border-radius 0px;
        }

        &__container {
            position: relative;

            &::before {
                content: "";
                position: absolute;
                top: 95px;
                left: -350px;
                z-index: 0;
                width: 400px;
                height: 405px;
                background-image: url($urlSpriteImage + "/theme-icons/blason.svg");
                background-repeat: no-repeat;
                background-size: cover;
            }
        }

        &__slide {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__informations {
            width: calc(50% - 235px);
            margin-bottom: -10px;
        }

        &__wrapper {
            width: calc(50% + 170px);
            border-radius: 0 10px 10px 0;
            overflow: hidden;

            img,
            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: block;
            }
        }

        &__link {
            border: 1px solid $color-white;
            border-radius: 50px;
            background-color: transparent;
            font-family: $font-family;
            font-size: toRem(14);
            font-weight: $font-weight-bold;
            line-height: toRem(20);
            width: fit-content;
            padding: 10px 19px;
            width: 140px;
            box-sizing: border-box;
            display: block;
            text-align: center;
            color: $color-white;
            transition: {
                property: background-color, border-color, color;
                duration: $duration;
                timing-function: $timing;
            }

            &:hover,
            &:focus {
                background-color: $color-white;
                border-color: $color-white;
                color: $color-dark;
            }
        }

        &__title {
            font-family: $font-family--second;
            font-size: toRem(35);
            font-weight: $font-weight-bold;
            line-height: toRem(45);
            color: $color-white;
            margin: 0 0 10px;
        }

        &__intro {
            font-family: $font-family;
            font-size: toRem(18);
            font-weight: $font-weight-normal;
            line-height: toRem(25);
            color: $color-white;
            margin-bottom: 30px;
        }

        .c-pagination {
            @include absolute($bottom: 20px, $right: 20px);
            @include flex($alignItems: center, $justifyContent: flex-end);
            gap: 10px;
            height: 25px;
            padding: 6px 15px;
            background-color: $color-second;
            border-radius: $btn-spe-border-radius;
            z-index: 2;
            pointer-events: auto;

            .nav-swiper-autoplay {
                @include size(12px);
                padding: 0;
                background-color: transparent;
                border: 0;
                border-radius: 0;
                margin: 0;

                &__icon {
                    @include size(12px);
                    fill: $color-dark;
                }

                &:hover,
                &:focus {
                    .nav-swiper-autoplay__icon {
                        fill: $color-dark;
                    }
                }
            }

            &__bullets {
                @include flex($alignItems: center);
                gap: 10px;

                .swiper-pagination-bullet {
                    @include size(12px);
                    margin: 0 !important;
                    opacity: 1 !important;
                    background-color: $color-dark;
                    border: 1px solid $color-dark;
                    border-radius: $border-radius--round;
                    transition: {
                        property: background-color, border-color;
                        duration: $duration;
                        timing-function: $timing;
                    }

                    &:hover,
                    &:focus,
                    &-active {
                        background-color: transparent;
                        border-color: $color-dark;
                    }
                }
            }
        }
    }
    .front-pagemt {
        margin-top: 0;
    }

    // ==================================
    // Access
    // ==================================
    .access {
        margin-top: 80px;
        position: relative;
        z-index: 2;

        &__list {
            display: flex;
            align-items: center;
            gap: 50px;
        }

        &__group {
            display: flex;
            align-items: center;
            gap: 50px;

            &:nth-child(1) {
                margin-left: 10px;
            }
        }

        &__item {
            width: 150px;

            &:hover,
            &:focus {
                svg {
                    circle {
                        fill: $color-third;
                    }
                }
            }
        }

        &__link {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 15px;
        }

        &__image-wrapper {
            width: 120px;
            height: 120px;
            background-color: $color-light;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: $border-radius--round;

            svg {
                circle {
                    transition: fill $duration $timing;
                }
            }
        }

        &__title {
            font-family: $font-family;
            font-size: toRem(18);
            font-weight: $font-weight-bold;
            line-height: toRem(25);
            color: $color-dark;
            text-align: center;
            text-transform: uppercase;
        }
    }

    // ==================================
    // Events
    // ==================================
    .events {
        margin-top: 155px;

        &__container {
            position: relative;

            &::before {
                content: "";
                position: absolute;
                top: 0px;
                left: -1485px;
                z-index: 2;
                width: 1500px;
                height: 500px;
                background-color: $color-white;
            }
        }

        .swiper-container {
            margin-top: -10px;
            overflow: visible;

            &:has(.events__item.swiper-slide-active.swiper-slide--odd) {
                .events__item.swiper-slide.swiper-slide--odd {
                    margin-bottom: 40px;
                    margin-top: 0;
                }
                .events__item.swiper-slide.swiper-slide--even {
                    margin-bottom: 0;
                    margin-top: 40px;
                }
            }

            &:has(.events__item.swiper-slide-active.swiper-slide--even) {
                .events__item.swiper-slide.swiper-slide--odd {
                    margin-bottom: 0;
                    margin-top: 40px;
                }
                .events__item.swiper-slide.swiper-slide--even {
                    margin-bottom: 40px;
                    margin-top: 0;
                }
            }
        }

        &__title {
            text-align: center;
            margin: 0;
            padding: 0;
            margin-left: 0px;

            &::after {
                content: "";
                position: absolute;
                top: -35px;
                left: calc(50% - 14px);
                z-index: 0;
                width: 50px;
                height: 25px;
                background-image: url($urlSpriteImage + "/theme-icons/deco_titre_centre.svg");
                background-repeat: no-repeat;
                background-size: cover;
            }
        }

        &__image {
            width: 100%;
            height: 200px;
            border-radius: 10px 10px 0 0;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &--notimg {
                background: url($urlShort + "posts-icons/post.svg") no-repeat $color-gray;
                background-position: center;
            }
        }

        &__date {
            position: absolute;
            top: 140px;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            align-items: center;
            justify-content: center;

            &--start,
            &--end,
            &--same p {
                border-radius: 100px;
                width: 95px;
                height: 95px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                font-family: $font-family;
                font-size: toRem(22);
                font-weight: $font-weight-black;
                line-height: toRem(22);
                color: $color-dark;
                text-transform: uppercase;
            }

            &--start {
                background-color: $color-light;
            }

            &--end {
                background-color: $color-second;
                margin-left: -15px;
            }

            &--same {
                p {
                    background-color: $color-light;
                }
            }
        }

        &__title--small {
            font-family: $font-family--second;
            font-size: toRem(22);
            font-weight: $font-weight-bold;
            line-height: toRem(30);
            color: $color-white;
            margin: 0;
            padding: 0;
            text-align: center;
            min-height: 90px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__item {
            position: relative;
            background-color: $color-main;
            border-radius: 10px 10px 10px 10px;
            height: auto;
            transition: {
                property: margin, opacity, background-color;
                duration: $duration;
                timing-function: $timing;
            }

            &__content {
                // background-color: $color-main;
                border-radius: 0 0 10px 10px;
                padding: 60px 40px 40px;
                // transition: background-color $duration $timing;
            }

            // &.swiper-slide {
            //     margin: 0 0 40px;
            // }

            // &.swiper-slide-active {
            //     &.swiper-slide--odd {
            //         & ~ .swiper-slide--odd {
            //             margin: 0 0 40px;
            //         }

            //         & ~ .swiper-slide--even {
            //             margin: 40px 0 0;
            //         }
            //     }

            //     &.swiper-slide--even {
            //         & ~ .swiper-slide--odd {
            //             margin: 40px 0 0;
            //         }

            //         & ~ .swiper-slide--even {
            //             margin: 0 0 40px;
            //         }
            //     }
            // }

            &:hover,
            &:focus {
                background-color: $color-dark;
                .events__item__content {
                    // background-color: $color-dark;
                }
            }
        }

        &__navigation {
            position: absolute;
            right: 15px;
            top: -30px;
            @include flex($alignItems: center);
            gap: 10px;

            &__button {
                @include size(40px);
                background-color: $color-dark;
                border-color: $color-dark;
                margin: 0;
                padding: 0;

                svg {
                    @include size(40px);
                    fill: $color-white;
                }

                &:hover,
                &:focus {
                    border-color: $color-main;
                    background-color: $color-main;

                    svg {
                        fill: $color-white;
                    }
                }
            }

            &--no-slide {
                display: none;
            }
        }

        &__buttons {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 15px;
            margin-top: 30px;
        }

        &__button {
            font-family: $font-family;
            font-size: toRem(14);
            font-weight: $font-weight-bold;
            line-height: toRem(20);
            color: $color-dark;
            letter-spacing: toRem(0.7);
            text-transform: uppercase;
            display: flex;
            align-items: center;
            height: 50px;
            justify-content: center;
            gap: 10px;
            padding: 10px 25px;
            border-radius: 50px;
            transition: {
                property: background-color, border-color, color, opacity;
                duration: $duration;
                timing-function: $timing;
            }

            svg {
                width: 20px;
                height: 20px;
                transition: fill $duration $timing;
            }

            &--proposer {
                background-color: $color-light;

                &:hover,
                &:focus {
                    background-color: $color-main;
                    color: $color-white;

                    svg {
                        fill: $color-white;
                    }
                }
            }

            &--tous {
                background-color: $color-second;

                &:hover,
                &:focus {
                    background-color: $color-dark;
                    color: $color-white;

                    svg {
                        fill: $color-white;
                    }
                }
            }
        }
    }

    // ==================================
    // News
    // ==================================
    .news {
        margin-top: 150px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            bottom: -60px;
            left: 0px;
            z-index: 0;
            width: 100%;
            height: 335px;
            background-color: $color-third;
        }

        &__container {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            &::before {
                content: "";
                position: absolute;
                bottom: -210px;
                left: -350px;
                z-index: 0;
                width: 400px;
                height: 405px;
                background-image: url($urlSpriteImage + "/theme-icons/blason.svg");
            }
        }

        &__title {
            margin: 0 0 25px;
            padding: 0;
            width: fit-content;

            &::after {
                content: "";
                position: absolute;
                top: -30px;
                left: -37px;
                z-index: 0;
                width: 35px;
                height: 42px;
                background-image: url($urlSpriteImage + "/theme-icons/deco_titre_gauche.svg");
                background-repeat: no-repeat;
                background-size: cover;
            }
        }

        &__image {
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &--notimg {
                background: url($urlShort + "posts-icons/post.svg") no-repeat $color-bg--image;
                background-position: center;
            }
        }

        .swiper-container {
            width: 100%;
        }

        &__item {
            width: 370px;
            height: 335px;
            position: relative;
            border-radius: 10px;
            overflow: hidden;

            &::before {
                // content: "";
                position: absolute;
                bottom: 0px;
                left: 0px;
                z-index: 0;
                width: 100%;
                height: 120px;
                background: rgba(18, 41, 43, 0.35);
                backdrop-filter: blur(10px);
                transition: height 0.45s $timing;
            }

            &:hover,
            &:focus {
                .news__introduction {
                    opacity: 1;
                    height: fit-content;
                }

                .news__content {
                    max-height: 100%;
                }

                .news__plus {
                    opacity: 1;
                }

                &::before {
                    height: 100%;
                }
            }
        }

        &__content {
            position: absolute;
            bottom: 0px;
            left: 0px;
            z-index: 1;
            width: 100%;
            padding: 30px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            max-height: 120px;
            height: 100%;
            justify-content: flex-start;
            backdrop-filter: blur(10px);
            background: rgba(18, 41, 43, 0.35);
            transition: {
                property: max-height, height, opacity;
                duration: 0.45s;
                timing-function: $timing;
            }
        }

        &__title--small {
            font-family: $font-family--second;
            font-size: toRem(22);
            font-weight: $font-weight-bold;
            line-height: toRem(30);
            color: $color-white;
            margin: 0 0 5px;
            text-align: center;
            margin-top: auto;
        }

        &__introduction {
            font-family: $font-family;
            font-size: toRem(16);
            line-height: toRem(25);
            font-weight: $font-weight-normal;
            color: $color-white;
            text-align: center;
            opacity: 0;
            margin-bottom: 20px;
            transition: {
                property: opacity, height;
                duration: 0.45s;
                timing-function: $timing;
            }
        }

        &__plus {
            opacity: 0;
            margin-bottom: auto;
            border: 1px solid $color-white;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 140px;
            height: 40px;
            font-family: $font-family;
            font-size: toRem(14);
            font-weight: $font-weight-bold;
            line-height: toRem(20);
            color: $color-white;
            transition: {
                property: background-color, border-color, color, opacity;
                duration: 0.45s;
                timing-function: $timing;
            }

            &:hover,
            &:focus {
                background-color: #fff;
                color: $color-dark;
            }
        }

        &__button {
            margin: 30px 0 0;

            background-color: $color-light;
            color: $color-dark;
            font-family: $font-family;
            font-size: toRem(14);
            font-weight: $font-weight-bold;
            line-height: toRem(20);
            color: $color-dark;
            letter-spacing: toRem(0.7);
            text-transform: uppercase;
            border: none;
            transition: {
                property: background-color, border-color, color, opacity;
                duration: $duration;
                timing-function: $timing;
            }

            svg {
                width: 20px;
                height: 20px;
                fill: $color-dark;
                transition: fill $duration $timing;
            }

            &:hover,
            &:focus {
                background-color: $color-dark;
                color: $color-white;

                svg {
                    fill: $color-white;
                }
            }
        }

        &__navigation {
            position: absolute;
            right: 15px;
            top: 5px;
            @include flex($alignItems: center);
            gap: 10px;

            &__button {
                @include size(40px);
                background-color: $color-dark;
                border-color: $color-dark;
                margin: 0;
                padding: 0;

                svg {
                    @include size(40px);
                    fill: $color-white;
                }

                &:hover,
                &:focus {
                    border-color: $color-main;
                    background-color: $color-main;

                    svg {
                        fill: $color-white;
                    }
                }
            }

            &--no-slide {
                display: none;
            }
        }
    }

    // ==================================
    // Contact
    // ==================================

    .contact {
        margin-top: 160px;

        &__wrapper {
            width: 100%;
            padding: 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            overflow: hidden;
            position: relative;
            background-color: $color-light;
            border-radius: 10px;

            &::before {
                content: "";
                position: absolute;
                top: -125px;
                left: -35px;
                z-index: 0;
                width: 440px;
                height: 440px;
                border-radius: $border-radius--round;
                background-color: rgba($color-third, 0.5);
            }
        }

        &__title {
            font-family: $font-family--second;
            font-size: toRem(30);
            font-weight: $font-weight-bold;
            line-height: toRem(35);
            padding: 0;
            margin: 0;
            position: relative;
            color: $color-dark;
            &::before {
                display: none;
            }

            &::after {
                content: "";
                position: absolute;
                top: -37.5px;
                right: -120px;
                top: -34.5px;
                z-index: 0;
                height: 80px;
                width: 115px;
                mask-image: url($urlSpriteImage + "/theme-icons/deco_titre_droit.svg");
                mask-repeat: no-repeat;
                mask-size: contain;
                background-color: $color-white;
                transform: rotate(30deg);
            }
        }

        &__buttons {
            display: flex;
            gap: 15px;
        }

        &__link {
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: $font-family;
            font-size: toRem(14);
            font-weight: $font-weight-bold;
            line-height: toRem(20);
            height: 50px;
            padding: 0 25px;
            letter-spacing: toRem(0.7);
            border-radius: 50px;
            text-transform: uppercase;
            transition: {
                property: background-color, border-color, color, opacity;
                duration: $duration;
                timing-function: $timing;
            }

            span {
                margin-right: 4px;
            }

            svg {
                width: 20px;
                height: 20px;
                transition: fill $duration $timing;
                margin-right: 10px;
            }

            &--newsletter {
                background-color: $color-second;
                color: $color-dark;

                svg {
                    fill: $color-dark;
                }
            }

            &--appli {
                background-color: $color-main;
                color: $color-white;

                svg {
                    fill: $color-white;
                }
            }

            &:hover,
            &:focus {
                background-color: $color-dark;
                color: $color-white;

                svg {
                    fill: $color-white;
                }
            }
        }
    }

    .hidden {
        display: none;
    }

    // =====================================================
    // kiosque
    // =====================================================

    .kiosque {
        margin-top: 139px;
        position: relative;

        &__content-big {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }

        &__title-big {
            text-align: center;
            margin: 0;
            padding: 0;
            width: fit-content;
            margin-left: -10px;

            &::after {
                content: "";
                position: absolute;
                top: -20px;
                right: -30px;
                z-index: 0;
                width: 43px;
                height: 35px;
                background-image: url($urlSpriteImage + "/theme-icons/deco_titre_droit.svg");
                background-repeat: no-repeat;
                background-size: contain;
            }
        }

        &__container {
            position: relative;
        }

        &__wrapper {
            margin-top: 30px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            position: relative;
            width: 100%;
        }

        &__item-big {
            display: flex;
            gap: 30px;
            align-items: center;
            width: calc(50% - 30px);
        }

        &__image-big {
            width: 255px;
            height: 335px;
            display: block;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
            border-radius: 10px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                color: $color-white;
            }

            &--notimg {
                background: url($urlSpriteImage + "/base-icons/documents-icons/documents.svg") no-repeat #565c61;
                background-position: 50%;
            }
        }

        &__item-content-big {
            width: 250px;
        }

        &__buttons-big {
            display: flex;
            gap: 15px;
            flex-direction: column;
        }

        &__title-big--small {
            font-family: $font-family--second;
            font-size: toRem(22);
            font-weight: $font-weight-bold;
            line-height: toRem(30);
            color: $color-dark;
            margin-bottom: 35px;
        }

        &__button-big {
            display: flex;
            align-items: center;
            justify-content: center;
            width: fit-content;
            gap: 10px;
            font-family: $font-family;
            font-size: toRem(14);
            font-weight: $font-weight-bold;
            line-height: toRem(20);
            letter-spacing: toRem(0.7);
            color: $color-dark;
            text-transform: uppercase;

            span {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $color-main;
                border-radius: 50px;
                transition: background-color $duration $timing;
            }

            svg {
                width: 40px;
                height: 40px;
                fill: $color-white;
                transition: fill $duration $timing;
            }

            &:hover,
            &:focus {
                span {
                    background-color: $color-dark;
                }

                svg {
                    fill: $color-white;
                }
            }
        }

        // small list
        &__list {
            height: fit-content;
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: calc(50% - 85px);
        }

        &__item {
            padding: 30px;
            background-color: $color-light;
            border-radius: 10px;
            height: 110px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }

        &__title--small {
            font-family: $font-family--second;
            font-size: toRem(20);
            font-weight: $font-weight-bold;
            line-height: toRem(25);
            color: $color-dark;
            max-width: 250px;
        }

        &__buttons {
            display: flex;
            gap: 10px;
        }

        &__button {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $color-white;
            border-radius: 50px;
            transition: background-color $duration $timing;

            svg {
                width: 40px;
                height: 40px;
                transition: fill $duration $timing;
            }

            &--ecouter {
                svg {
                    width: 30px;
                    height: 30px;
                }
            }

            &:hover,
            &:focus {
                background-color: $color-main;

                svg {
                    fill: $color-white;
                }
            }
        }

        &__template--button {
            background-color: $color-main;
            color: $color-white;
            position: absolute;
            top: 96px;
            right: 15px;
            height: 50px;
            border-radius: 50px;
            padding: 0 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: $font-family;
            font-size: toRem(14);
            font-weight: $font-weight-bold;
            line-height: toRem(20);
            color: $color-white;
            text-transform: uppercase;
            letter-spacing: toRem(0.7);
            transition: {
                property: background-color, border-color, color, opacity;
                duration: $duration;
                timing-function: $timing;
            }

            svg {
                fill: $color-white;
                width: 20px;
                height: 20px;
                margin-left: 10px;
            }

            &:hover,
            &:focus {
                background-color: $color-dark;

                svg {
                    fill: $color-white;
                }
            }
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .front-page {
        // margin-top: 110px;
        &__title {
            font-size: toRem(30);
            line-height: toRem(35);
        }

        .slideshow {
            &__container {
                &::before {
                    top: 85px;
                    left: -260px;
                    width: 310px;
                    height: 315px;
                }
            }

            &__informations {
                width: calc(50% - 135px);
            }

            &__wrapper {
                width: calc(50% + 85px);
            }

            &__title {
                font-size: toRem(30);
                line-height: toRem(35);
                margin-bottom: 5px;
            }

            &__intro {
                font-size: toRem(16);
                line-height: toRem(25);
                margin-bottom: 20px;
            }
        }

        .access {
            margin-top: 60px;

            &__list {
                gap: 40px;
            }

            &__group {
                gap: 40px;
            }

            &__item {
                width: 120px;
            }

            &__title {
                font-size: toRem(16);
                line-height: toRem(20);
            }
        }

        .events {
            margin-top: 131px;
            &__title {
                margin: 0;
                &::after {
                    transform: translateX(-50%);
                    top: -30px;
                    left: calc(50% - 0px);
                }
            }

            &__container {
                &::before {
                    left: -1490px;
                }
            }

            &__image {
                height: 165px;
            }

            &__date {
                top: 115px;

                &--start,
                &--end,
                &--same p {
                    border-radius: 100px;
                    width: 80px;
                    height: 80px;
                    font-size: toRem(20);
                    line-height: toRem(20);
                }

                &--end {
                    margin-left: -10px;
                }
            }

            &__title--small {
                font-size: toRem(18);
                line-height: toRem(25);
                min-height: 75px;
            }

            &__navigation {
                right: 10px;
                top: -25px;
            }

            .swiper-container {
                margin-top: 0;

                &:has(.events__item.swiper-slide-active.swiper-slide--odd) {
                    .events__item.swiper-slide.swiper-slide--odd {
                        margin-bottom: 30px;
                        margin-top: 0;
                    }
                    .events__item.swiper-slide.swiper-slide--even {
                        margin-bottom: 0;
                        margin-top: 30px;
                    }
                }

                &:has(.events__item.swiper-slide-active.swiper-slide--even) {
                    .events__item.swiper-slide.swiper-slide--odd {
                        margin-bottom: 0;
                        margin-top: 30px;
                    }
                    .events__item.swiper-slide.swiper-slide--even {
                        margin-bottom: 30px;
                        margin-top: 0;
                    }
                }
            }

            &__item {
                &__content {
                    padding: 50px 30px 30px;
                }
            }
        }

        .news {
            margin-top: 130px;
            &__item {
                width: 300px;
                height: 280px;
            }

            .swiper-container {
                margin-top: 5px;
            }

            &__content {
                padding: 25px;
                max-height: 100px;
                gap: 8px;
            }

            &__title--small {
                font-size: toRem(18);
                line-height: toRem(25);
            }

            &__introduction {
                font-size: toRem(14);
                line-height: toRem(20);
            }

            &__navigation {
                right: 10px;
            }
        }

        .contact {
            &__wrapper {
                padding: 25px;

                &::before {
                    width: 350px;
                    height: 350px;
                    top: -100px;
                    left: -55px;
                    z-index: 0;
                }
            }

            &__buttons {
                position: relative;
            }

            &__title {
                font-size: toRem(22);
                line-height: toRem(25);

                &::after {
                    right: -85px;
                    height: 65px;
                    width: 95px;
                }
            }

            &__link {
                font-size: toRem(12);
                line-height: toRem(20);
                padding: 0 23px;
            }
        }

        .kiosque {
            margin-top: 120px;
            &__title-big--small {
                margin-bottom: 30px;
                font-size: toRem(18);
                line-height: toRem(25);
            }

            &__item-content-big {
                width: 200px;
            }

            &__image-big {
                width: 225px;
                height: 295px;
            }

            &__item-big {
                gap: 20px;
                width: calc(50% - 25px);
            }

            &__list {
                width: calc(50% - 70px);
            }

            &__title--small {
                font-size: toRem(16);
                line-height: toRem(20);
            }

            &__item {
                padding: 25px;
                height: fit-content;
            }

            &__template--button {
                top: 80px;
                right: 10px;
            }
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .front-page {
        .slideshow {
            &::before {
                display: none;
            }

            &__container {
                &::before {
                    z-index: 2;
                    left: -100px;
                    top: 365px;
                    bottom: unset;
                }
            }

            .swiper-container {
                background-color: transparent;
                border-radius: unset;
            }

            &__slide {
                flex-direction: column-reverse;
                height: auto;
                align-items: stretch;
                border-radius: 10px 10px 10px 10px;
                overflow: hidden;
                background-color: #11646b;
            }

            &__informations {
                width: 100%;
                background-color: $color-main;
                border-radius: 0 0 10px 10px;
                padding: 55px;
                box-sizing: border-box;
                // flex-grow: 1;
                margin-bottom: unset;
            }

            &__link {
                position: relative;
                left: 50%;
                transform: translateX(-50%);
            }

            &__title {
                text-align: center;
                margin: 0 0 5px;
            }

            &__intro {
                text-align: center;
            }

            &__wrapper {
                width: 100%;
                height: 310px;
                border-radius: 10px 10px 0 0;
            }

            .c-pagination {
                left: 50%;
                transform: translateX(-50%);
                right: unset;
                bottom: unset;
                top: 265px;
            }
        }

        .access {
            margin-top: 58px;

            &__list {
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                gap: 50px;
            }

            &__group {
                gap: 80px;

                &:first-child {
                    margin-left: 0;
                }
            }
        }

        .events {
            &__item {
                position: relative;

                &.swiper-slide {
                    margin: 0;
                }

                &.swiper-slide-active {
                    &.swiper-slide--odd {
                        & ~ .swiper-slide--odd {
                            margin: 0;
                        }

                        & ~ .swiper-slide--even {
                            margin: 0;
                        }
                    }

                    &.swiper-slide--even {
                        & ~ .swiper-slide--odd {
                            margin: 0;
                        }

                        & ~ .swiper-slide--even {
                            margin: 0;
                        }
                    }
                }
            }

            &__container {
                &::before {
                    display: none;
                }
            }

            .swiper-container {
                margin-top: 0;

                &:has(.events__item.swiper-slide-active.swiper-slide--odd) {
                    .events__item.swiper-slide.swiper-slide--odd {
                        margin-bottom: 0;
                        margin-top: 0;
                    }
                    .events__item.swiper-slide.swiper-slide--even {
                        margin-bottom: 0;
                        margin-top: 0;
                    }
                }

                &:has(.events__item.swiper-slide-active.swiper-slide--even) {
                    .events__item.swiper-slide.swiper-slide--odd {
                        margin-bottom: 0;
                        margin-top: 0;
                    }
                    .events__item.swiper-slide.swiper-slide--even {
                        margin-bottom: 0;
                        margin-top: 0;
                    }
                }
            }

            &__image {
                height: 165px;
            }

            &__title {
                margin-bottom: 30px;
            }

            &__navigation {
                top: -6px;
            }
        }

        .news {
            &__item {
                &:hover,
                &:focus {
                    .news__introduction {
                        opacity: 0;
                    }

                    .news__content {
                        max-height: 100px;
                    }

                    .news__plus {
                        opacity: 0;
                    }
                }
            }

            &__content {
                height: fit-content;
            }

            &__introduction {
                display: none;
            }

            &__plus {
                display: none;
            }

            &__navigation {
                top: -6px;
            }

            &__button {
                padding: 10px 27.5px;
            }
        }

        .contact {
            &__wrapper {
                flex-direction: column;
                justify-content: flex-start;
                gap: 20px;
                align-items: center;

                &::before {
                    top: -100px;
                    left: -135px;
                }
            }

            &__title {
                &::after {
                    display: none;
                }
            }

            &__buttons {
                justify-content: center;
                flex-wrap: wrap;
            }
        }

        .kiosque {
            &__wrapper {
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                gap: 30px;
                width: 100%;
                margin-top: 30px;
            }

            &__item-big {
                width: unset;
                margin-left: 0px;
            }

            &__template--button {
                position: static;
                margin-left: 0px;
                margin-top: 50px;
            }

            &__content-big {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
            }

            &__title--small {
                width: 100%;
                max-width: unset;
            }

            &__list {
                flex-direction: row;
                width: 100%;
                gap: 15px;
            }

            &__item {
                width: calc(50% - 7.5px);
                height: unset;

                &-content-big {
                    width: 225px;
                }
            }

            &__content {
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                gap: 20px;
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .front-page {
        margin-top: 0;
        .slideshow {
            &__container {
                max-width: unset;
                padding: 0;
                margin: 0;

                &::before {
                    top: 315px;
                    bottom: unset;
                    left: -132px;
                }
            }

            &__wrapper {
                border-radius: 0;
                height: 250px;
            }

            &__informations {
                border-radius: 0;
                padding: 30px 0;
            }

            &__slide {
                border-radius: 0;
            }

            &__title {
                margin: 0px auto 5px;
                max-width: 90%;
                padding: 0 10px;
                font-size: toRem(20);
                line-height: toRem(35);
            }

            &__intro {
                margin: 0 auto 20px;
                max-width: 90%;
                padding: 0 10px;
                font-size: toRem(14);
                line-height: toRem(20);
            }

            .c-pagination {
                top: 210px;
                height: 20px;

                .nav-swiper-autoplay__icon {
                    width: 10px;
                    height: 10px;
                }

                &__bullets {
                    gap: 8px;
                    .swiper-pagination-bullet {
                        width: 10px;
                        height: 10px;
                    }
                }
            }
        }

        .access {
            &__list {
                gap: 30px;
            }

            &__group:first-child {
                margin-left: 5px;
            }

            &__group {
                justify-content: space-between;
                width: 395px;
                gap: unset;
                margin-left: 5px;
            }

            &__item {
                width: 85px;
            }

            &__link {
                gap: 10px;
            }

            &__title {
                font-size: toRem(14);
                line-height: toRem(20);
            }

            &__image-wrapper {
                width: 80px;
                height: 80px;

                svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .events {
            margin-top: 110px;
            &__buttons {
                flex-wrap: wrap;
            }

            &__navigation {
                position: static;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &__title {
                min-height: 50px;
                margin-bottom: 15px;
            }

            &__title--small {
                min-height: 50px;
            }

            &__buttons {
                margin-top: 20px;
                gap: 30px 15px;
            }
        }

        .news {
            margin-top: 110px;
            &__navigation {
                margin-top: 20px;
                position: static;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &__title {
                margin-right: -20px;
            }

            &__content {
                padding: 22.5px;
            }
        }

        .contact {
            margin-top: 140px;
            &__wrapper {
                &::before {
                    top: -70px;
                    left: -110px;
                }
            }
            &__link {
                span {
                    display: none;
                }
            }
        }

        .kiosque {
            margin-top: 100px;

            &::before {
                height: 310px;
                top: 146px;
            }

            &__title-big {
                position: relative;
                margin-left: 1px;
            }

            &__item-big {
                flex-direction: column;
                width: 225px;
                margin-left: 0px;
                gap: 20px;
            }

            &__wrapper {
                margin-top: 30px;
            }

            &__title-big--small {
                text-align: center;
            }

            &__item-content-big {
                width: 100%;
            }

            &__buttons-big {
                flex-direction: row;
                gap: 15px;
                justify-content: center;
                margin-top: -9px;
                margin-left: 7px;
            }

            &__button-big {
                gap: 0;
                color: $color-white;
                font-size: toRem(0.1);
                line-height: toRem(0.1);
                width: 40px;
                height: 40px;
            }

            &__list {
                flex-direction: column;
            }

            &__template--button {
                margin-left: 0px;
                margin-top: 30px;
            }

            &__item {
                width: 100%;
            }
        }
    }
}

// 640
@media screen and (max-width: 440px) {
    .front-page {
        .access {
            &__group {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: $x-small) {
    .front-page {
        .events {
            &__title {
                margin-bottom: 15px;
            }

            &__button--tous {
                margin-top: -15px;
            }
        }

        .news {
            &__title {
                margin-right: 0px;
            }
        }

        .kiosque {
            &__buttons-big {
                gap: 15px;
                margin-right: -5px;
            }

            &__button-big {
                width: 40px;
            }

            &__template--button {
                margin-top: 28px;
            }
        }
    }
}
