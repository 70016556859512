//======================================================================================================
// Quick Access
//======================================================================================================
.q-access {
    position: fixed;
    top: 0;
    right: -910px;
    z-index: 15;
    display: block;
    height: 100%;
    width: 920px;
    background-color: $color-second;
    transition: all 0.75s;

    &__title {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        right: 820px;
        top: 50%;
        width: 230px;
        height: 50px;
        color: $color-white;
        background-color: transparent;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
        transform: rotate(-90deg);

        span {
            margin-top: 10px;

            &::before,
            &::after {
                content: "\f343";
                font: normal 20px/1 dashicons;
                position: absolute;
                right: 30px;
                color: $color-white;
                transition: all $duration;
            }

            &::after {
                left: 30px;
                right: inherit;
            }
        }

        svg {
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            fill: $color-second;
            transform: rotate(180deg);
        }

        &:hover,
        &:focus {
            background-color: transparent;

            svg {
                fill: $color-second;
            }

            span {
                color: $color-white;
            }
        }
    }

    &__close {
        @include absolute($top: 20px, $right: 20px);
        z-index: 1;
        line-height: 0;
        transition: all $duration $timing;

        svg {
            @include size(40px);
            fill: $color-white;
            border: $border-width $border-style transparent;
            border-radius: $border-radius--round;
            transition: border-color $duration $timing;
        }

        &:hover,
        &:focus {
            svg {
                border-color: $color-white;
                fill: $color-white;
            }
        }

        &--ally {
            @include absolute($bottom: 20px, $right: 20px);
            z-index: 1;
            pointer-events: none;
            width: max-content;
            height: 0;
            padding: 0;
            background-color: $color-bg--transparent;
            border: $border-width $border-style $color-white;
            color: $color-white;
            text-align: center;
            border-radius: 30px;
            opacity: 0;

            &:focus {
                opacity: 1;
                height: auto;
                padding: 15px 30px;
                color: $color-white;
                border-color: $color-white;
                background-color: $color-bg--transparent;
                transition: opacity $duration $timing;
            }
        }
    }

    &__container {
        padding-left: 75px;
        height: 100%;

        h2 {
            color: $color-white;
            border: 0;
            margin: 90px 0 50px;
            text-transform: uppercase;
        }

        ul {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            width: 560px;
            max-height: 560px;
            column-gap: 30px;

            li {
                width: calc(50% - 30px);
                margin-bottom: 15px;

                a {
                    display: block;
                    width: 100%;
                    color: $color-white;
                    text-decoration: underline;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    &.js-open {
        right: 0;

        .q-access__title {
            span {
                &::before,
                &::after {
                    transform: rotate(180deg);
                }
            }
        }
    }
}

.admin-bar .q-access__close {
    top: calc(20px + 32px);
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .q-access {
        width: 890px;
        right: -880px;

        &__title {
            right: 790px;
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .q-access {
        display: none;
    }
}
