.flexible {
    margin-top: 30px;
}

//======================================================================================================
// Bloc Document
//======================================================================================================
.bloc-document {
    margin-top: 30px;
}

//======================================================================================================
// Bloc Contact
//======================================================================================================
.bloc-contact {
    margin-top: 30px;

    .card {
        &__content {
            padding: 0;
        }
    }
}

//======================================================================================================
// Bloc Video
//======================================================================================================
.bloc-video {
    &__wrapper {
        margin-top: 30px;
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .grid-center {
        width: calc(100% + 20px);
    }
}
