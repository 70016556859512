.cover {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: calc(50vw - 170px);
        z-index: -1;
        background-color: $color-main;
    }

    &--notimg {
        .cover__container {
            background-color: $color-main;
            border-radius: 0 10px 10px 0;
        }

        .cover__informations {
            width: calc(100% - 70px);
        }
    }

    &__container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 95px;
            left: -350px;
            z-index: 0;
            width: 400px;
            height: 405px;
            background-image: url($urlSpriteImage + "/theme-icons/blason.svg");
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    &__informations {
        width: calc(50% - 215px);
        padding: 70px 0;
        margin: auto 0;
    }

    &__image-wrapper {
        width: calc(50% + 170px);
        border-radius: 0 10px 10px 0;
        overflow: hidden;

        picture {
            width: 100%;
            height: 100%;
            display: block;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
        }
    }

    &__title {
        font-family: $font-family--second;
        font-size: toRem(35);
        font-weight: $font-weight-bold;
        line-height: toRem(40);
        color: $color-white;
        margin: 10px 0;
    }

    &__intro {
        font-family: $font-family;
        font-size: toRem(18);
        font-weight: $font-weight-bold;
        line-height: toRem(25);
        color: $color-white;
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .cover {
        &::before {
            width: calc(50vw - 85px);
        }

        &__container {
            &::before {
                left: -270px;
                top: 85px;
                width: 310px;
                height: 315px;
            }
        }
        &__informations {
            width: calc(50% - 135px);
        }
        &__image-wrapper {
            width: calc(50% + 85px);
        }

        &__title {
            font-size: toRem(30);
            line-height: toRem(35);
            margin: 5px 0;
        }

        &__intro {
            font-size: toRem(16);
            line-height: toRem(25);
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .cover {
        &::before {
            display: none;
        }

        &--notimg {
            .cover__container {
                border-radius: 10px;
            }

            &::before {
                display: block;
            }
        }

        &__container {
            flex-direction: column-reverse;

            &::before {
                left: -100px;
                bottom: -110px;
                top: unset;
            }
        }

        &__image-wrapper {
            width: 100%;
            height: 310px;
            border-radius: 10px 10px 0 0;
        }

        &__slide {
            height: auto;
        }

        &__informations {
            width: 100%;
            background-color: $color-main;
            border-radius: 0 0 10px 10px;
            padding: 55px;
            box-sizing: border-box;
        }

        &__title {
            text-align: center;
        }

        &__intro {
            text-align: center;
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .cover {
        &__container {
            max-width: unset;
            padding: 0;
            margin: 0;

            &::before {
                top: 315px;
                bottom: unset;
                left: -132px;
            }
        }

        &--notimg {
            .cover__container {
                border-radius: 0;
            }

            .cover__informations {
                margin: 0 auto;
            }
        }

        &__image-wrapper {
            border-radius: 0;
            height: 250px;
        }

        &__informations {
            border-radius: 0;
            padding: 30px 0;
        }

        &__title {
            margin: 5px auto;
            max-width: 90%;
            padding: 0 10px;
        }

        &__lead-wrapper {
            margin: 0 auto;
            max-width: 90%;
            padding: 0 10px;
        }
    }
}
